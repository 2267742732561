/deep/ .ant-modal-body {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.form-item {
  display: flex;
  font-size: 0.18rem;
  color: #555555;
  line-height: 1.667;
  margin-bottom: 0.1rem;
}

.form-item .label {
  width: 1.7rem;
  flex-shrink: 0;
}

.form-item .input {
  flex: 1;
}

.ant-input {
  width: 100%;
  margin-top: 0.05rem;
  font-size: 0.18rem;
  color: #555555;
  border: 0.01rem solid #e5e5e5;
  border-radius: 0.1rem;
}

.imgs /deep/ img {
  margin-bottom: 0.05rem;
  object-fit: cover;
  cursor: zoom-in;
  margin-right: 0.05rem;
}